import { RefObject } from "react";

export const getFirstVisibleCardIndex = (
  containerRef: RefObject<HTMLDivElement>,
  cardRefs: RefObject<(HTMLDivElement | null)[]>
) => {
  const containerElement = containerRef.current;
  const cardElements = cardRefs.current;

  if (
    !containerElement ||
    !cardElements ||
    cardElements.length === 0 ||
    !cardElements[0]
  ) {
    return 0;
  }

  const firstCardOffsetLeft = cardElements[0].offsetLeft;

  const firstVisibleIndex = cardElements.findIndex((cardElement) => {
    const cardOffsetLeft = cardElement?.offsetLeft ?? 0;
    const cardStart = cardOffsetLeft - firstCardOffsetLeft;
    return cardStart >= containerElement.scrollLeft;
  });

  return firstVisibleIndex;
};
